import axios from "../api/axios";
import html2pdf from 'html2pdf.js';
import Axios from "axios";
import Cookies from "js-cookie";
import { twit } from "../components/Notificationpopout";
import { DateTime  } from "luxon";



//function to get data
export const getData  = async (url,setLoading)=>{
  setLoading(true);
    const token = sessionStorage.getItem('token');


    try {
      const response = await axios.get(url,{
        headers: {  Authorization: `Bearer ${token}`
    }

    });

      setLoading(false);
       return {data:response,error:null}
       
    } catch (err) {
      setLoading(false);
        return {data:null,error:err}
        
    }
    
    
  }


  //function to patch data
  export const patchData = async (url,payload,setLoading) => {

    const token = sessionStorage.getItem('token');

    setLoading(true);

    try {
        const response = await axios.patch(url,
            JSON.stringify(payload),
            {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            }

        );
        setLoading(false);
        return {data:response,error:null}

    } catch (err) {
        setLoading(false);
        return {data:null,error:err}
        
    }

}

//function to download a pdf by element id
export const downloadPDF = (id,setPrintable,name,dimensions,orientation) => {
  const element = document.getElementById(id);
  html2pdf(element, {
    output: '/invoice folder',
    filename: name? `${name}.pdf`:'invoice.pdf',
    html2canvas:{useCors:false,scale:5},
    jsPDF: { unit: 'mm', format:dimensions? dimensions:'a4', orientation: orientation? orientation:'portrait' },
  });
  setTimeout(function () {
    setPrintable(false);
  }, 2000);
}

export const downloadPDFV2 = (id,name,dimensions,orientation) => {
  const element = document.getElementById(id);
  html2pdf(element, {
    output: '/invoice folder',
    filename: name? `${name}.pdf`:'invoice.pdf',
    html2canvas:{useCors:false,scale:5},
    jsPDF: { unit: 'mm', format:dimensions? dimensions:'a4', orientation: orientation? orientation:'portrait' },
  });
  
}


// calculating percentage
 export const Percentage = (numerator, denominator) => {

  const percent = (numerator / denominator) * 100;

  return percent;
};


// truncate string

export const truncateString = (str,length) => {
  return str.slice(0, length);
};

export const validateImageDimensions = (file,shape) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = URL.createObjectURL(file);

    image.onload = () => {
      const width = image.width;
      const height = image.height;
      const aspectRatio = width / height;

      
      

      if (shape==='rectangularLogo'&&(aspectRatio<3.5||aspectRatio>3.9)) {
        reject(new Error('Please upload an image with a 2:1 aspect ratio.'));
      }else if (shape==='squareLogo'&& aspectRatio!==1 ) {
        reject(new Error('Please upload an image with a 1:1 aspect ratio.'));
      }else if (shape==='cardTemplate' && !(aspectRatio>1.59 &&aspectRatio<1.75) ) {
        reject(new Error('Please upload an image with an aspect ratio(width/height) between 1.59 and 1.75.'));
      } else {
        resolve(aspectRatio);
      }
    };

    
  });
};

// process.env.EMAIL_VALIDATION_KEY



//function to validate user email
export const validateEmails= async(email)=>{

  try {

const response = await Axios.get(`http://apilayer.net/api/check?access_key=d39d79b7dc4d152dbb58d5bdd26776ab&email=${email}&smtp=1&format=1` );
    console.log(response)
    if (response.data.format_valid&&response.data.smtp_check&&!response.data.disposable&&response.data.mx_found) {
      return Promise.resolve(true);
    } else {
      return Promise.reject("Email provided is not valid.");
    }
  } catch (error) {
     return Promise.reject("An error occurred during email validation.");
  }
 

}

//function to log user out
 export const logOut = async(message,setLoading) => {



const token = sessionStorage.getItem('token');

setLoading(true)
const response = await axios.delete('/api/auth/logout/client', {
    
    headers: {
      'Content-Type': 'application/json', Authorization: `Bearer ${token}`
    }
    
  ,
  data: {
    
  }
});
setLoading(false)
if(response.status === 201){
  Cookies.remove('loggedin');
  Cookies.remove('CollAccess');
  localStorage.clear();
  sessionStorage.clear()
  window.location.href = '/';
  twit("info",message);
}else{
  twit("info","logout failed.");
}

  
 
}


//function to validate user phoneNumber
export const validatePhoneNumber= async(phone)=>{

  const number = phone.slice(3);

  try {

const response = await Axios.get(`http://apilayer.net/api/validate?access_key=3432afd39e13b713f061ca14b6cb7edd& number=${number}&country_code=UG& format=1` );
    console.log(response,number);
    if (response.data.valid&&response.data.line_type==='mobile') {
      return Promise.resolve(true);
    } else {
      return Promise.reject("Phone number provided is not valid.");
    }
  } catch (error) {
     return Promise.reject("An error occurred during phone number validation.");
  }
 

}

export const validateLocation = async ()=>{
try {
   const response = await Axios.get('http://ip-api.com/json/');
  
  // if(response.data.country==='Uganda') {
  //   return Promise.resolve(true)
  // }else{
  //   return Promise.reject(`We are not available in ${response.data.country} yet.`)
  // }

  return Promise.resolve(true)
  
} catch (error) {
  return Promise.resolve(true)
  //return Promise.reject('An error occurred while validating your location.')
}


}

//get random number 1,2 or 3

export const getRandomNumber=()=>{
  return Math.floor(Math.random() * 3) + 1;
}


//function to split an array into chunks
export const splitArrayIntoChunks=(array, chunkSize)=> {
  const chunks = [];
  for (let i = 0; i < array?.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
}

export const threeDigits =(number)=> {
  // Check the length of the number.
  var length = number.toString().length;

  // If the length is 1, add two zeroes before the number.
  if (length === 1) {
    return "00" + number;
  } else if (length === 2) {
    // If the length is 2, add one zero before the number.
    return "0" + number;
  } else {
    // If the length is 3, don't add any zeroes.
    return number;
  }
}

export const twoDigits =(number)=> {
  // Check the length of the number.
  var length = number.toString().length;

  // If the length is 1, add two zeroes before the number.
  if (length === 1) {
    return "0" + number;
  } else {
    // If the length is 3, don't add any zeroes.
    return number;
  }
}

export const generateLabelNumber=(inputDate,index)=> {
  const dateObj = new Date(inputDate);
  const day = String(dateObj.getUTCDate()).padStart(2, '0');
  const month = String(dateObj.getUTCMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = dateObj.getUTCFullYear();

  return day + month + year+'-'+threeDigits(index);
}


//function to get invoice index 

const getInvoiceIndex = () => {
  // Get the current date in yyyy-mm-dd format.
  const currentDate = new Date().toISOString().slice(0, 10);

  // Check if there's a stored date in local storage.
  const storedDate = sessionStorage.getItem("invoiceDate");

  if (storedDate !== currentDate) {
    // Reset the invoice number to 1 for a new day.
    sessionStorage.setItem("invoiceDate", currentDate);
    sessionStorage.setItem("invoiceIndex", 1);
    
    return 1;
  } else {
    
    // Retrieve the stored invoice number and increment it.
    const storedInvoiceIndex = sessionStorage.getItem("invoiceIndex");
    const newInvoiceIndex = storedInvoiceIndex
      ? parseInt(storedInvoiceIndex) + 1
      : 1;

    // Store the updated invoice number in local storage.
    sessionStorage.setItem("invoiceIndex", newInvoiceIndex);
    
    return newInvoiceIndex;
  }


}



// function to generate invoice number
export const getInvoiceNumber = (companyName)=>{
  const invoiceIndex = getInvoiceIndex()
  // Convert the date string to a Date object.
  const date_obj = new Date();

  // Get the current date in the format "dd/yyyy".
  const currentDate = date_obj.toLocaleDateString("en-US", {
    format: "dd/yyyy"
  });

  const day = date_obj.toLocaleDateString("en-US", {
    day: '2-digit'
  });

  // Get the first three letters of the company name.
  const companyNameFirstThreeLetters = companyName.slice(0, 3);

  // Get the last two digits of the year.
  const year = date_obj.getFullYear();
  const lastTwoDigitsOfYear = year.toString().slice(-2);

  // Return the invoice number in the format "name/dd/yyyy-number".
  return `${companyNameFirstThreeLetters}/${day}/${lastTwoDigitsOfYear}-${threeDigits(invoiceIndex)}`;
}


// function to get all date info from iso string
export const parseISODate = (isoString) => {

  if(!isoString){
    return ''
  }
  const eventDate = new Date(isoString);

  const year = eventDate.getFullYear();
  const month = eventDate.getMonth() + 1; // Month is 0-based, so add 1
  const day = eventDate.getDate();
  const hours = eventDate.getHours();
  const minutes = eventDate.getMinutes();
  const seconds = eventDate.getSeconds();

  return {
    year,
    month,
    day,
    hours,
    minutes,
    seconds,
  };
};

const addOrdinalSuffix = (day) => {
  if (day == 1 || day == 21 || day == 31) {
    return `${day}st`;
  } else if (day == 2 || day == 22) {
    return `${day}nd`;
  } else if (day == 3 || day == 23) {
    return `${day}rd`;
  } else {
    return `${day}th`;
  }
};


const getDayFromIsoString =(isoString)=>{
  const date = new Date(isoString);

  const options = { weekday: 'long' };
  const day = date.toLocaleString('en-US', options);

  return day;
}

const getDayOfWeekFromISOString = (isoString)=>{
  // Split the ISO string to get the date part
  const datePart = isoString.split('T')[0];
  
  // Split the date part to get the year, month, and day
  const [year, month, day] = datePart.split('-');

  // Create an array to map the index returned by getDay() to the day name
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  // Create an array to map the index returned by getMonth() to the month name
  const months = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December'
  ];

   
  // Create a new Date object based on the year, month, and day
  const date = new Date(year, month - 1, day);

  // Get the day of the week index (0 for Sunday, 1 for Monday, etc.)
  const dayIndex = date.getDay();

  // Return the day of the week name
  return  {wordDay:daysOfWeek[dayIndex],wordMonth:months[month - 1],day,year};
}




export const getformattedDate = (isoString) => {

  if(!isoString){
    return ' '
  }
 
  const {wordDay,wordMonth,year,day} = getDayOfWeekFromISOString(isoString)
  const eventDate = new Date(isoString);

  const hours = eventDate.getHours();
  const minutes = eventDate.getMinutes();
  const seconds = eventDate.getSeconds();

  
  

  

  const dateWord = `${addOrdinalSuffix(day)} ${wordMonth.substring(0, 3)} ${year}`;;
  const timeWord = ` ${hours<3?((hours-3)+24):hours-3}:${twoDigits(minutes)} HRS`;
  
  return  dateWord+ timeWord

};

export const getformattedDate2 = (isoString) => {

  if(!isoString){
    return ' '
  }


  //const wordDay = getDayFromIsoString(isoString)
  const {wordDay,wordMonth,year,day} = getDayOfWeekFromISOString(isoString)


  const eventDate = new Date(isoString);

  const hours = eventDate.getHours();
  const minutes = eventDate.getMinutes();
  const seconds = eventDate.getSeconds();

  
  const dateWord = `${addOrdinalSuffix(day)} ${wordMonth.substring(0, 3)} ${year}`;
  const timeWord = ` ${hours<3?((hours-3)+24):hours-3}:${twoDigits(minutes)} HRS (${wordDay})`;
  
  return  dateWord+ timeWord

};


export const removeSpacesInString =(inputString)=> {
  // Use a regular expression to replace all spaces with an empty string
  return inputString.replace(/\s/g, '');
}

export const getNairobiTime = () => {
  const date = DateTime.fromISO(new Date().toISOString(), { zone: "utc" }).plus({ hours: 3 }).toString()
  
  return date
 };
 
 export const getNairobiTimeWithParam = (dateValue ) => {
   
    if(!dateValue){
      return ''
    }
   const date = DateTime.fromISO(dateValue?.toISOString(), { zone: "utc" }).toString()
   
   return date
  };
 
  export const containsUniqueIdentifier=(array)=>{
    const uniqueIdentifiers = ['GUID', 'UUID', 'UID', 'ObjectID', 'ID'];
  const regex = /^[0-9A-F]{8}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{4}-[0-9A-F]{12}$/i;

  for (const item of array) {
    if (uniqueIdentifiers.includes(typeof item)) {
      return true;
    } else if (typeof item === 'string' && regex.test(item)) {
      return true;
    }
  }
  return false;
  }


  export const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  // export const capitalizeFirstLetter = (str) => {
  //   return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  // };
  export const getNumberOnly = (data)=>{
   return data.replace(/[^0-9]/g, '');
  }

  export const formatPrice = (price) => {
    if(!price){
      return('0')
    }
   
    const priceStr = price.toString();
  
    
    if (priceStr.includes('.')) {
      
      const [wholePart, decimalPart] = priceStr.split('.');
  
      
      const formattedWholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
      
      return `${formattedWholePart}.${decimalPart}`;
    } else {
      
      return priceStr.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  export const  downloadContactCSV =(contact)=>{
    // Generate VCF content
  // const vcfContent = `
  // BEGIN:VCARD
  // VERSION:3.0
  // FN:${contact.name}
  // EMAIL:${contact.email}
  // TEL:${contact.phone}
  // END:VCARD
  // `;
  
 

  const vcfContent = `BEGIN:VCARD\r\nVERSION:3.0\r\nFN:${contact.name}\r\nEMAIL:${contact.email}\r\nTEL:${contact.phone}\r\nEND:VCARD`;
  
    // Create a Blob from the VCF content
    const blob = new Blob([vcfContent], { type: 'text/vcard' });
  
    // Create a download link
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = `${contact.name}.vcf`;
  
    // Append the link to the body
    document.body.appendChild(downloadLink);
  
    // Trigger the click event to start the download
    downloadLink.click();
  
    // Remove the link from the body
    document.body.removeChild(downloadLink);
  }
  
 export const downloadImage = (imageUrl) => {
    return new Promise((resolve, reject) => {
      fetch(imageUrl)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to download image: ${response.status} ${response.statusText}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            resolve(reader.result);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(blob);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  

  export const isFileSizeExceed = (maxSizeInMB, files)=> {
    const maxSizeInBytes = maxSizeInMB * 1024 * 1024; // Convert MB to bytes
    for (let i = 0; i < files.length; i++) {
      if (files[i].size > maxSizeInBytes) {
        return true; // At least one file exceeds the max size
      }
    }
    return false; // None of the files exceed the max size
  }
  
  export const checkAspectRatio = async (file, desiredAspectRatio)=>{
    
  
    return new Promise((resolve, reject) => {
      if (!file || !file.type.startsWith("image/")) {
        reject(new Error("Invalid file type. Please select an image."));
        return;  
      }
  
      const reader = new FileReader();
      reader.readAsDataURL(file);
  
      reader.onload = function (event) {
        const image = new Image();
        image.src = event.target.result;
  
        image.onload = function () {
          const width = image.naturalWidth;
          const height = image.naturalHeight;
          const actualAspectRatio = width / height;
  
          resolve(Math.abs(actualAspectRatio - desiredAspectRatio) < 0.01); // Account for slight rounding errors
        };
  
        image.onerror = function (error) {
          reject(new Error("Error loading image: " + error.message));
        };
      };
  
      reader.onerror = function (error) {
        reject(new Error("Error reading image file: " + error.message));
      };
    });
  }
  export const convertFileToDataURL = async (inputFile)=> {
    if (!inputFile) {
      return ''
    }
  
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = function(event) {
        resolve(event.target.result);
      };
      reader.onerror = function(error) {
        reject(error);
      };
      reader.readAsDataURL(inputFile);
    });
  }
  

  export const isMTNAirtel =(phoneNumber)=>{
    // Validate phone number format (Ugandan)

    
    if (!phoneNumber || phoneNumber.length !== 12|| phoneNumber.slice(0, 3) !== "256") {
      return " ";
    }
  
    const mtnRanges = [ "77", "78"];
    const airtelRanges = ["70", "75"];
  
    const prefix = phoneNumber.slice(3, 5);
  
    

    if (mtnRanges.includes(prefix)) {
      return "MTN";
    } else if (airtelRanges.includes(prefix)) {
      return "AIRTEL";
    } else {
      return "OTHER";
    }
  }

  export const isMTNAirtelVisa =(account)=>{
    

    
    if (!account) {
      return " ";
    }
  
    const mtnRanges = [ "77", "78"];
    const airtelRanges = ["70", "75"];
  
    const prefix = account.slice(3, 5);
  
    
    if (mtnRanges.includes(prefix)) {
      return "MTN";
    } else if (airtelRanges.includes(prefix)) {
      return "AIRTEL";
    } else {
      return "OTHER";
    }
  }
  
  export const capitalizeFirstLetters = (data)=> {
    // Convert the sentence to lowercase for easier handling
  
    if(!data){
      return ''
    }
  
    let sentence = String(data)
    sentence = sentence?.toLowerCase();
  
    // Split the sentence into an array of words
    const wordsRaw = sentence?.split(" ");

    const words = wordsRaw?.filter((data)=>{
      return (data)
    })
  
    // Loop through each word and capitalize the first letter
    for (let i = 0; i < words.length; i++) {
      words[i] = words[i][0]?.toUpperCase() + words[i]?.slice(1);
    }
  
    // Join the modified words back into a sentence
    return words.join(" ");
  }


  export const simulateLoading = ( setLoadingFunction, durationInSec )=>{

    if(!setLoadingFunction||!durationInSec){
      return false
    }
    
    const durationInMs = durationInSec * 1000;
  
    
    setLoadingFunction(true); 
  
    
    setTimeout(() => {
      
      setLoadingFunction(false);
    }, durationInMs);
  }