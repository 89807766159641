import Missing from './components/Missing';
import { Routes, Route, useLocation } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import RegisterCarousel from './components/RegisterCarousel';
import ThreeStepForm from './components/MultistepForm';
import Login2 from './components/Login2';
import EmailVerification from './components/Emailverification';
import Sidebar from './components/Dashboard2';
import { Container } from 'react-bootstrap';
import Ticket from './components/Tickets';
import NavBar from './components/NavBar';
import { useState, useEffect } from 'react';
import Meetings from './components/Meetings';
import MeetingDash from './components/MeetingDash';
import Testing from './components/Testing';
import IdentityDash from './components/IdentityDash'
import io from 'socket.io-client';
import IdentityProducts from './components/IdentityProducts';
import Identity from './components/Identity';
import IdentityRegistration from './components/Views/IdentityRegistsration';
import ProtectedRoute from './components/ProtectedRoutes';
import Cookies from 'js-cookie';
import MainDashboard from './components/Views/MainDashboard';
import CardDesign from './components/Views/CardDesign';
import Documents from './components/Views/Documents';
import ConsentPage from './components/Views/ConsentPage';
import Memberships from './components/Views/Memberships';
import Loyalty from './components/Views/Loyalty';
import SettingsCard from './components/Views/Settings';
import GeneralSettings from './components/GeneralSettings';
import { getNairobiTime, validateLocation } from './Utilities/utilities';
import { twit } from './components/Notificationpopout';
import CenteredFlagMessage from './components/LocationPage';
import CreateInvoice from './components/Views/CreateInvoice';
import PaymentPage from './components/Views/PaymentConfirmation';
import TagTest from './components/TagGeneratorTest';
import OrderTags from './components/Views/OrderTags';
import Loader from './components/Loader';
import { apiBaseUrl, pinnisoftUrl } from './Utilities/globals';
import PassCard from './components/Views/Pass';
import PassCategory from './components/PassCategory';
import EventMenu from './components/Views/EventMenu';
import EventsOutlet from './components/Views/EventsOutlet';
import EventForm from './components/Views/EventForm';
import AssetManagementCard from './components/Views/AssetManagement';
import AssetsDashboard from './components/Views/AssetsDashboard';
import UserEvents from './components/Views/UserEvent';
import EventCredentialForm from './components/Views/EventCredentialForm';
import UserEventPasses from './components/Views/UserEventPasses';
import AssetsOperationOutlet from './components/Views/AssetsOperationsOutlet';
import AssetOperationsMenu from './components/Views/AssetsOperationsMenu';
import BranchForm from './components/Views/BranchForm';
import UserBranches from './components/Views/UserBranches';
import AssetsBranchOutlet from './components/Views/AssetsBranchOutlet';
import BranchLoginPage from './components/Views/branchLogin';
import BranchHome from './components/Views/BranchHome';
import ProtectedBranchRoute from './components/ProtectedBranchRoute';
import AssetsBranchDash from './components/Views/AssetsBranchDash';
import EventCouponForm from './components/Views/EventCouponForm';
import EventZoneForm from './components/Views/EventZoneForm';
import IdentityPass from './components/Views/IdentityPass';
import EventManagementCard from './components/Views/EventManagementCard';
import EventAgenda from './components/Views/EventAgendaDemo';
import EventStatistics from './components/eventStatistics';
import SrhrAgenda from './components/Views/Srhrdemo';
import EventManagementHome from './components/Views/EventManagementHome';
import EventsManagementOutlet from './components/Views/EventManagementOutlet';
import CreatedEvents from './components/Views/CreatedEvents';
import CreateEventForm from './components/Views/CreateEventForm';
import EventManagementAddsOn from './components/Views/EventManagementAddsOn';
import UpdateEventForm from './components/Views/EventUpdateForm';
import PassCategoryForm from './components/Views/CreatePassCategoryForm';
import PassVerification from './components/Views/PassVerification';
import TagPurchase from './components/Views/TagPurchase';
import loginBackground from './images/pinniTagsBG2.jpg'
import BillingOutlet from './components/Views/BillingOutlet';
import BillingOverview from './components/Views/BillingOverview';
import BillingAccounts from './components/Views/BillingAccounts';
import EventsAddsOnOutlet from './components/Views/EventsAddsOnOutlet';
import DescriptionPage from './components/Views/EventsAddsonDesc';
import BillsSummary from './components/Views/BillsSummary';
import CreditsSummary from './components/Views/CreditsSummary';
import BillingPurchaseOrders from './components/Views/BillingPuchaseOrders';
import Home from './components/Views/Home';
import EventSponsorForm from './components/Views/EventSponsorsForm';
import { fetchData } from './Utilities/apiRequests';
import useGeneralState from './hooks/hooks/generalState';
import useInactivitySignOut, { useCountdown } from './components/Inactivity';
import { Modal } from 'antd';
import * as MdIcon from 'react-icons/md';
import TicketPassVerification from './components/Views/TicketPassVerification';
import CollectionAccountSettings from './components/Views/CollectionAccountSettings';
import ProtectedInnerRoute from './components/ProtectedInnerRoute';
import CollectionsOutlet from './components/Views/CollectionsOutlet';
import TransactionCollections from './components/Views/TransactionCollections';
import EventCollections from './components/Views/EventCollections';
import CashOuts from './components/Views/CashOuts';
import CashOutRequests from './components/Views/CashOutRequests';
import EventsOrdersOutlet from './components/Views/OrdersOutlet';
import EventManagementOrders from './components/Views/EventsManagementOrders';
import PhysicalTagPurchase from './components/Views/PhysicalTagOrder';
import EventsPassAddsonOutlet from './components/Views/EventsPassAddsOnOutlet';
import EventPassAddsOnHome from './components/Views/EventPassAddsOnHome';
import EventPassAddsOnIdentities from './components/Views/EventPassAddsOnIdentites';
import EventBatchManagement from './components/Views/EventBatchManagement';
import EventTicketAddsOnHome from './components/Views/EventTicketAddsOnHome';
import EventTicketSellerForm from './components/Views/CreateEventTicketSellersForm';
import EventTicketSellerManagement from './components/Views/EventTicketSellerManagement';
import EventDonationCollections from './components/Views/EventDonationCollections';



function App() {





  const path = useLocation().pathname
  const [isopen, SetIsopen] = useState(false);
  const [isValidLocation, setIsValidLocation] = useState(false)
  const [socket, setSocket] = useState(null);
  const [locationMessage, setLocationMessage] = useState('')
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [profile, setProfile] = useState(null);
  const [loadingProfile, setLoadingProfile] = useState(false);






  useEffect(() => {
    validateLocation().then(() => {

      setIsValidLocation(true);
    }).catch((message) => {
      setLocationMessage(message);
      setIsValidLocation(false)
    })
  }, [])





  const handlesidebar = () => {
    SetIsopen(!isopen);
  }





  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);


    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  useEffect(() => {


    if (screenWidth < 1000) {
      SetIsopen(false);
    }

  }, [window.innerWidth]);


  //socket configuration
  useEffect(() => {


    const newSocket = io(apiBaseUrl,{transports: ['websocket', 'polling']});
    setSocket(newSocket);

    if (window.innerWidth > 1000) {
      SetIsopen(true);
    }

  }, []);

  


  useEffect(() => {


    const newSocket = io(apiBaseUrl,{transports: ['websocket', 'polling']});
    setSocket(newSocket);

    if (window.innerWidth < 1000) {
      SetIsopen(false);
    }

  }, []);


  // logout function

  const logOut = () => {
    Cookies.remove('loggedin');
    Cookies.remove('CollAccess');
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = '/';
    twit("info", 'You have been logged out due to inactivity.');
  }


 




  //inactivity hook

  //const { showWarning, isActive } = useInactivitySignOut(logOut, 1740, 60)
  
  const { showWarning, isActive , secondsLeft} = useInactivitySignOut(logOut, 1740, 60)

  const loggedin = Cookies.get('loggedin');
  const generalAppState = useGeneralState();

  useEffect(() => {
    if (loggedin && (!profile)) {
      fetchData(`/api/auth/user/profile`, setLoadingProfile).then((response) => {
        setProfile(response.data.data)
        generalAppState?.actions.setCurrency(response.data.data.curr)
      }).catch((error) => {
        setLoadingProfile(false)
        console.log('User profile loading error:', error.response?.data?.message ? error.response?.data?.message : 'error loading user profile')
      })
    }

  }, [loggedin]);



   //inactivity countdown hook
  //  const { secondsLeft, startCountdown, clearCountdown } = useCountdown(60)

  //  useEffect(()=>{

  //       if(showWarning){
  //         startCountdown()
  //       }else{
  //         clearCountdown()
  //       }
 
  //  },[showWarning])




  const isNeld = path.includes("/event/agenda")
  const isSRHR = path.includes("/event/srhr")
  const isPass = path.includes("/pass/verify")
  const isPassTicket = path.includes("/passticket/registration")


  const Branchloggedin = Cookies.get('Branchloggedin');







  if (!isValidLocation) {
    if (!locationMessage) {
      return (<Loader />)
    } else {
      return (<CenteredFlagMessage message={locationMessage} />)
    }

  } else if (loggedin && !profile) {

    return (<Loader />)


  } else {

    return (
      <div className={((loggedin&&(!isPass||!isPassTicket)) || isNeld || isSRHR ) ? 'App-Inner' : 'App'} style={path === "/" ? { background: `url(${loginBackground})`, backgroundSize: 'cover' } : {}}>

        {showWarning && <Modal open={true} title={'Inactivity Warning.'} closable={false}
          footer={[


          ]}

          className="allmodals"
        >

          <div className='text-muted' style={{ marginTop: '25px', display: 'flex', justifyContent: 'center',alignItems:'center',flexDirection:'column' }}>
            <p style={{fontSize:'12px'}}>You are about to be signed out in {secondsLeft} seconds.</p>
            <MdIcon.MdTimer className='mx-1' style={{ width: '50px', height: '50px' }} />
            <p style={{fontSize:'12px'}}>Click anywhere to continue session.</p>
          </div>


        </Modal>}

        {(!path.includes("/verifyPayment") && path !== "/" && path !== "/form" && path !== "/verification" && !path.includes("/identityregistration")
          && !path.includes("/consent") && !path.includes("/assetBranch") && !path.includes("/event/agenda") && !path.includes("/event/srhr") && !path.includes("/pass/verify")&&!isPassTicket
        ) && (
            <Container className={isopen ? "App3-container" : "App4-container"}>

              <Sidebar open={isopen} showSidebar={handlesidebar} />
            </Container>

          )}



        <Container className={(loggedin) ? 'App2-container-inner' : 'App2-container'}>
          {(!path.includes("/verifyPayment") && path !== "/" && path !== "/form" && path !== "/verification" && !path.includes("/identityregistration")
            && !path.includes("/consent") && !path.includes("/assetBranch") && !path.includes("/event/agenda") && !path.includes("/event/srhr") && !path.includes("/pass/verify") &&!isPassTicket

          ) && (


              <NavBar isopen={isopen} showSidebar={handlesidebar} profile={profile} />


            )}









          <Routes  >
            {/* Public routes */}


            <Route path="/" element={<Login2 />} />
            <Route path="/verification" element={<EmailVerification />} />
            <Route path="/identityregistration/:id" element={<IdentityRegistration />} />
            <Route path="/form" element={<ThreeStepForm />} />
            <Route path="/consent/:hid/:rid" element={<ConsentPage />} />
            <Route path="/verifyPayment/:id" element={<PaymentPage />} />
            <Route path="/event/neld/:tag" element={<EventAgenda />} />
            <Route path="/event/srhr/:tag" element={<SrhrAgenda />} />
            <Route path="/pass/verify/:tag" element={<PassVerification />} />
            <Route path="/passticket/registration/:tagType/:tag" element={<TicketPassVerification/>} />
            


            {/* Private routes */}

            <Route path="dash" element={<ProtectedRoute loggedin={loggedin} ><Dashboard /></ProtectedRoute>} />
            <Route path="/carousel" element={<ProtectedRoute loggedin={loggedin} ><RegisterCarousel /></ProtectedRoute>} />
            <Route path="/ticket" element={<ProtectedRoute loggedin={loggedin} ><Ticket /></ProtectedRoute>} />
            <Route path="/meetings" element={<ProtectedRoute loggedin={loggedin} ><Meetings /></ProtectedRoute>} />
            <Route path="/meetingdash" element={<ProtectedRoute loggedin={loggedin} ><MeetingDash /></ProtectedRoute>} />
            <Route path="/identitydash" element={<ProtectedRoute loggedin={loggedin} ><IdentityDash dasht={true} /></ProtectedRoute>} />
            <Route path="/identitydashProducts" element={<ProtectedRoute loggedin={loggedin} ><IdentityDash prod={true} dasht={false} /></ProtectedRoute>} />
            <Route path="/identityProducts" element={<ProtectedRoute loggedin={loggedin} ><IdentityProducts /></ProtectedRoute>} />
            <Route path="/human" element={<ProtectedRoute loggedin={loggedin}><Identity origin={false} /></ProtectedRoute>} />
            <Route path="/human/myidentities" element={<ProtectedRoute loggedin={loggedin}><Identity origin={"products"} /></ProtectedRoute>} />
            <Route path="/human/cardoperations" element={<ProtectedRoute loggedin={loggedin}><Identity origin={"operations"} /></ProtectedRoute>} />
            <Route path="/identityupdate/:id" element={<ProtectedRoute loggedin={loggedin}><IdentityRegistration /></ProtectedRoute>} />
            <Route path="/home" element={<ProtectedRoute loggedin={loggedin}><Home /></ProtectedRoute>} />
            <Route path="/carddesign" element={<ProtectedRoute loggedin={loggedin}><CardDesign /></ProtectedRoute>} />
            <Route path="/documents" element={<ProtectedRoute loggedin={loggedin}><Documents /></ProtectedRoute>} />
            <Route path="/memberships" element={<ProtectedRoute loggedin={loggedin}><Memberships /></ProtectedRoute>} />
            <Route path="/loyalty" element={<ProtectedRoute loggedin={loggedin}><Loyalty /></ProtectedRoute>} />
            <Route path="/create/invoice" element={<ProtectedRoute loggedin={loggedin}><CreateInvoice /></ProtectedRoute>} />
            <Route path="/tagsTest" element={<ProtectedRoute loggedin={loggedin}><TagTest /></ProtectedRoute>} />
            <Route path="/ordertags" element={<ProtectedRoute loggedin={loggedin}><OrderTags /></ProtectedRoute>} />

            <Route path="/pass" element={<ProtectedRoute loggedin={loggedin}><PassCard /></ProtectedRoute>} >

              <Route index element={<ProtectedRoute loggedin={loggedin}><PassCategory /></ProtectedRoute>} />
              <Route path="event" element={<ProtectedRoute loggedin={loggedin}><EventsOutlet /></ProtectedRoute>}>
                <Route index element={<ProtectedRoute loggedin={loggedin}><EventMenu /></ProtectedRoute>} />
                <Route path="form" element={<ProtectedRoute loggedin={loggedin}><EventForm /></ProtectedRoute>} />
                <Route path="events" element={<ProtectedRoute loggedin={loggedin}><UserEvents /></ProtectedRoute>} />
                <Route path="credentialform/:eid" element={<ProtectedRoute loggedin={loggedin}><EventCredentialForm /></ProtectedRoute>} />
                <Route path="eventzoneform/:eid" element={<ProtectedRoute loggedin={loggedin}><EventZoneForm /></ProtectedRoute>} />
                <Route path="eventcouponform/:eid" element={<ProtectedRoute loggedin={loggedin}><EventCouponForm /></ProtectedRoute>} />
                <Route path="identitypass" element={<ProtectedRoute loggedin={loggedin}><IdentityPass /></ProtectedRoute>} />
                <Route path="passes" element={<ProtectedRoute loggedin={loggedin}><UserEventPasses /></ProtectedRoute>} />
                <Route path="statistics/:eid" element={<ProtectedRoute loggedin={loggedin}><EventStatistics /></ProtectedRoute>} />
              </Route>


            </Route>

            <Route path="/events" element={<ProtectedRoute loggedin={loggedin}><EventManagementCard /></ProtectedRoute>} >

              <Route index element={<ProtectedRoute loggedin={loggedin}><div style={{ width: '100%', textAlign: 'center' }}>Events Dashboard</div></ProtectedRoute>} />
              <Route path="products" element={<ProtectedRoute loggedin={loggedin}><EventsManagementOutlet /></ProtectedRoute>}>
                <Route index element={<ProtectedRoute loggedin={loggedin}><EventManagementHome /></ProtectedRoute>} />
                <Route path="myEvents" element={<ProtectedRoute loggedin={loggedin}><EventsManagementOutlet /></ProtectedRoute>}>
                  <Route index element={<ProtectedRoute loggedin={loggedin}><CreatedEvents /></ProtectedRoute>} />
                  <Route path="passCheckers/:eid" element={<ProtectedRoute loggedin={loggedin}><EventCredentialForm /></ProtectedRoute>} />
                  <Route path="zoneManagement/:eid" element={<ProtectedRoute loggedin={loggedin}><EventZoneForm /></ProtectedRoute>} />
                  <Route path="statistics/:eid" element={<ProtectedRoute loggedin={loggedin}><EventStatistics /></ProtectedRoute>} />
                  <Route path="couponManagement/:eid" element={<ProtectedRoute loggedin={loggedin}><EventCouponForm /></ProtectedRoute>} />
                  <Route path="passManagement/:eid" element={<ProtectedRoute loggedin={loggedin}><PassCategoryForm /></ProtectedRoute>} />
                  <Route path="sponsorManagement/:eid" element={<ProtectedRoute loggedin={loggedin}><EventSponsorForm /></ProtectedRoute>} />
                  <Route path="batchManagement/:eid" element={<ProtectedRoute loggedin={loggedin}><EventBatchManagement /></ProtectedRoute>} />
                  <Route path="sellerManagement/:eid" element={<ProtectedRoute loggedin={loggedin}><EventTicketSellerManagement /></ProtectedRoute>} />
                  <Route path="create" element={<ProtectedRoute loggedin={loggedin}><CreateEventForm /></ProtectedRoute>} />
                  <Route path="update/:eid" element={<ProtectedRoute loggedin={loggedin}><UpdateEventForm /></ProtectedRoute>} />
                </Route>
              </Route>

              <Route path="addson" element={<ProtectedRoute loggedin={loggedin}><EventsAddsOnOutlet /></ProtectedRoute>}>
                <Route index element={<ProtectedRoute loggedin={loggedin}><EventManagementAddsOn /></ProtectedRoute>} />
                <Route path="description" element={<ProtectedRoute loggedin={loggedin}><DescriptionPage /></ProtectedRoute>} />

                <Route path="pass" element={<ProtectedRoute loggedin={loggedin}><EventsPassAddsonOutlet /></ProtectedRoute>}>
                  <Route index element={<ProtectedRoute loggedin={loggedin}><EventPassAddsOnHome/></ProtectedRoute>} />
                  <Route path="identities" element={<ProtectedRoute loggedin={loggedin}><EventPassAddsOnIdentities /></ProtectedRoute>} />
                </Route>

                <Route path="ticket" element={<ProtectedRoute loggedin={loggedin}><EventsPassAddsonOutlet /></ProtectedRoute>}>
                  <Route index element={<ProtectedRoute loggedin={loggedin}><EventTicketAddsOnHome/></ProtectedRoute>} />
                  <Route path="ticketSellers" element={<ProtectedRoute loggedin={loggedin}><EventTicketSellerForm /></ProtectedRoute>} />
                </Route>



              </Route>
              {/* <Route path="orders" element={<ProtectedRoute loggedin={loggedin}><TagPurchase /></ProtectedRoute>} /> */}

              <Route path="orders" element={<ProtectedRoute loggedin={loggedin}><EventsOrdersOutlet /></ProtectedRoute>}>
                <Route index element={<ProtectedRoute loggedin={loggedin}><EventManagementOrders /></ProtectedRoute>} />
                <Route path="digital" element={<ProtectedRoute loggedin={loggedin}><TagPurchase /></ProtectedRoute>} />
                <Route path="physical" element={<ProtectedRoute loggedin={loggedin}><PhysicalTagPurchase /></ProtectedRoute>} />
              </Route>


            </Route>

            <Route path="/billing" element={<ProtectedRoute loggedin={loggedin}><BillingOutlet /></ProtectedRoute>} >

              <Route index element={<ProtectedRoute loggedin={loggedin}><BillingOverview /></ProtectedRoute>} />
              <Route path="accounts" element={<ProtectedRoute loggedin={loggedin}><BillingAccounts /></ProtectedRoute>} />
              <Route path="bills" element={<ProtectedRoute loggedin={loggedin}><BillsSummary /></ProtectedRoute>} />
              <Route path="credits" element={<ProtectedRoute loggedin={loggedin}><CreditsSummary /></ProtectedRoute>} />
              <Route path="purchaseOrders" element={<ProtectedRoute loggedin={loggedin}><BillingPurchaseOrders /></ProtectedRoute>} />

            </Route>

            <Route path="/collections" element={<ProtectedRoute loggedin={loggedin}><CollectionsOutlet /></ProtectedRoute>} >

              <Route index element={<ProtectedRoute loggedin={loggedin}><ProtectedInnerRoute><TransactionCollections/></ProtectedInnerRoute></ProtectedRoute>} />
              <Route path="events" element={<ProtectedRoute loggedin={loggedin}><ProtectedInnerRoute><EventCollections /></ProtectedInnerRoute></ProtectedRoute>} />
              <Route path="donations" element={<ProtectedRoute loggedin={loggedin}><ProtectedInnerRoute><EventDonationCollections /></ProtectedInnerRoute></ProtectedRoute>} />
              <Route path="cashOuts" element={<ProtectedRoute loggedin={loggedin}><ProtectedInnerRoute><CashOuts /></ProtectedInnerRoute></ProtectedRoute>} />
              <Route path="requests" element={<ProtectedRoute loggedin={loggedin}><ProtectedInnerRoute><CashOutRequests /></ProtectedInnerRoute></ProtectedRoute>} />

            </Route>

            <Route path="/assets" element={<ProtectedRoute loggedin={loggedin}><AssetManagementCard /></ProtectedRoute>} >

              <Route index element={<ProtectedRoute loggedin={loggedin}><AssetsDashboard /></ProtectedRoute>} />
              <Route path="operations" element={<ProtectedRoute loggedin={loggedin}><AssetsOperationOutlet /></ProtectedRoute>}>
                <Route index element={<ProtectedRoute loggedin={loggedin}><AssetOperationsMenu /></ProtectedRoute>} />
                <Route path="branchform" element={<ProtectedRoute loggedin={loggedin}><BranchForm /></ProtectedRoute>} />
                <Route path="branches" element={<ProtectedRoute loggedin={loggedin}><UserBranches /></ProtectedRoute>} />
              </Route>


            </Route>

            <Route path="/assetBranch/:bid" element={<AssetsBranchOutlet />} >

              <Route index element={<BranchLoginPage />} />
              <Route path="home" element={<ProtectedBranchRoute loggedin={Branchloggedin}><BranchHome /></ProtectedBranchRoute>}>
                <Route index element={<ProtectedBranchRoute loggedin={Branchloggedin}><AssetsBranchDash /></ProtectedBranchRoute>} />
                <Route path="form" element={<ProtectedRoute loggedin={loggedin}><EventForm /></ProtectedRoute>} />
                <Route path="events" element={<ProtectedRoute loggedin={loggedin}><UserEvents /></ProtectedRoute>} />
                <Route path="credentialform/:eid" element={<ProtectedRoute loggedin={loggedin}><EventCredentialForm /></ProtectedRoute>} />
                <Route path="passes" element={<ProtectedRoute loggedin={loggedin}><UserEventPasses /></ProtectedRoute>} />
              </Route>


            </Route>


            <Route path="/settings" element={<ProtectedRoute loggedin={loggedin}><SettingsCard /></ProtectedRoute>} >

              <Route index element={<ProtectedRoute loggedin={loggedin}><GeneralSettings /></ProtectedRoute>} />
              <Route path="general" element={<ProtectedRoute loggedin={loggedin}><p></p></ProtectedRoute>} />
              <Route path="notifications" element={<ProtectedRoute loggedin={loggedin}><p></p></ProtectedRoute>} />
              <Route path="collections" element={<ProtectedRoute loggedin={loggedin}><ProtectedInnerRoute><CollectionAccountSettings/></ProtectedInnerRoute></ProtectedRoute>} />

            </Route>
            <Route path="*" element={<ProtectedRoute loggedin={loggedin}><Missing /></ProtectedRoute>} />
            <Route path="/test" element={<Testing />} />






          </Routes>

          {(!path.includes("/verifyPayment") && path !== "/" && path !== "/form" && path !== "/verification" && !path.includes("/identityregistration")
            && !path.includes("/consent") && !path.includes("/assetBranch") && !path.includes("/event/agenda") && !path.includes("/event/srhr") && !path.includes("/pass/verify") &&!isPassTicket

          ) && (

              <div style={{ paddingLeft: '14px', paddingRight: '14px' }}>
                <div className='footer' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', maxWidth: '100%', backgroundColor: "#fff", borderRadius: "4px" }} >

                  <p className='my-0' style={{ fontSize: "10px", color: "black", textAlign: 'center' }}>
                    Copright &copy; {getNairobiTime().slice(0, 4)} PinniTAGS is a product of <a href={pinnisoftUrl} className="link">PinniSOFT</a>  <br />
                    All rights reserved.
                  </p>

                </div>

              </div>

            )}

        </Container>

      </div>

    );
  }
}
export default App;