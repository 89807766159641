import axios from 'axios';


export default axios.create({
    baseURL: 'https://pinnitagsapiv1.westus.cloudapp.azure.com'
});


// export default axios.create({
//     baseURL: 'https://api.pinnitags.com/'
// });

// export default axios.create({
//     baseURL: 'http://192.168.186.127:9091'
// });

// export default axios.create({
//     baseURL: 'http://192.168.100.104:9091'
// });



// export default axios.create({
//     baseURL: 'http://192.168.1.196:9091'
// });

// export default axios.create({
//     baseURL: 'http://13.64.231.198:80'
// });

// export default axios.create({
//     baseURL: 'http://192.168.100.32/'
// });