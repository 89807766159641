import React, { useEffect, useState } from 'react';
import { Form, Container, Row, Col, Table, Card } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Loader from '../Loader';
import { Button, Modal } from 'antd';
import { twit } from '../Notificationpopout';
import axios from '../../api/axios';
import { convertFileToDataURL, downloadImage, downloadPDF, getNairobiTime, getformattedDate, removeSpacesInString, simulateLoading, splitArrayIntoChunks, threeDigits } from '../../Utilities/utilities';
import { fetchData } from '../../Utilities/apiRequests';
import * as MdIcons from 'react-icons/md';
import * as AiIcons from 'react-icons/ai';
import * as TiIcons from 'react-icons/ti';
import * as HiIcons from 'react-icons/hi';
import * as GiIcon from 'react-icons/gi';
import * as GrIcon from 'react-icons/gr';
import * as BsIcons from 'react-icons/bs';
import { ImCross } from "react-icons/im";
import { IoIosAddCircleOutline } from "react-icons/io";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import { FcExpired } from "react-icons/fc";
import visualPlacer from '../images/imagePlaceholder.png'
import { ReactMultiEmail } from 'react-multi-email';
import io from 'socket.io-client';
import { apiBaseUrl, fileUploadBaseUrl } from '../../Utilities/globals';
import PassPage from '../PassA4';
import ColorCube from '../ColorCube';
import ImagePreview from '../ImagePreview';
import ImageCropper from '../ImageCropper';
import Pagination from '../Pagination';
import PhoneInput from 'react-phone-input-2';

const EventTicketSellerForm = () => {


    const [sname, setSname] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [ticketSellers, setTicketSellers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [faceImage, setFaceImage] = useState(false);
    const [sellerRowData, setSellerRowData] = useState();
    const [updateSellerInfo, setUpdateSellerInfo] = useState();
    const [imageLoaded, setImageLoaded] = useState(true);
    const [socket, setSocket] = useState(null);
    const [email, setEmail] = useState('');
    const [showCropper, setShowCropper] = useState(false);
    const [cropperImage, setCropperImage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [location, setLocation] = useState('');
    const [phone, setPhone] = useState('');
    const [isActive, setIsActive] = useState(false);



    const faceformData = new FormData()
    faceformData.append("image", faceImage);





    useEffect(() => {

        fetchData(`/api/events/ticketSellers/all`, setLoading).then((response) => {
            setTicketSellers(response.data.sellers)
        }).catch((error) => {
            twit('info', error.response?.data?.message ? error.response?.data?.message : 'error loading sellers.')
        })

    }, [refresh])


    useEffect(() => {

        const newSocket = io(apiBaseUrl,{transports: ['websocket', 'polling']});
        setSocket(newSocket);

    }, []);








    const createTicketSeller = async (setLoading) => {
        const token = sessionStorage.getItem('token');

        const faceImageData = await convertFileToDataURL(faceImage)



        const postData = {
            name: sname,
            phone,
            email,
            imageData: faceImageData,
            location,
        };

        const Url = '/api/events/ticketSellers/create'
        try {
            setLoading(true);
            const response = await axios.post(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                return Promise.resolve('Seller created successfully.')

            }


        } catch (err) {
            setLoading(false);
            console.log("Event Pass  Error:", err)
            return Promise.reject(err.response.data.message ? err.response.data.message : 'An error occured while creating box office seller.')

        }
    };

    const updateTicketSeller = async (setLoading) => {
        const token = sessionStorage.getItem('token');

        const faceImageData = await convertFileToDataURL(faceImage)

        const postData = {
            name: sname ? sname : sellerRowData.name,
            email: email ? email : sellerRowData.email,
            sid: sellerRowData.id,
            imageData: faceImageData,
            location,
            status: isActive,
            phone:phone ? phone : sellerRowData.phone
        };

        const Url = '/api/events/ticketSellers/update'
        try {
            setLoading(true);
            const response = await axios.patch(Url, JSON.stringify(postData), {
                headers: {
                    'Content-Type': 'application/json', Authorization: `Bearer ${token}`
                }

            });

            if (response.status === 201) {

                setLoading(false);
                return Promise.resolve('Seller updated successfully.')

            }


        } catch (err) {
            setLoading(false);
            console.log("Seller update Error:", err.response?.message)
            return Promise.reject(err.response?.data?.message ? err.response?.data?.message : 'An error occured while updating seller.')

        }
    };


    const handleSellerSubmit = (e) => {
        e.preventDefault();

        if (!sname) {
            twit('info', 'Please provide the name.')
        } else {
            createTicketSeller(setLoading).then((data) => {
                twit('success', data)
                setRefresh(!refresh)
                setSname('');
                setEmail('');
                setFaceImage('')
                setLocation('')
                setPhone('')
            }).catch((error) => {
                twit('info', error)
            })

        }

    };


    const handleSellerSubmitUpdate = (e) => {
        e.preventDefault();

        updateTicketSeller(setLoading).then((data) => {
            twit('success', data)
            setRefresh(!refresh)
            setSname('');
            setEmail('');
            setUpdateSellerInfo(false)
            setFaceImage('')
            setLocation('')
            setPhone('')
            setIsActive(false)
        }).catch((error) => {
            twit('info', error)
        })

    };


    const resetInputsState = () => {
        setSname('');
        setEmail('');
        setUpdateSellerInfo(false)
        setFaceImage('')
        setLocation('')
        setPhone('')
        setIsActive(false)
    }





    //pagination logic
    const postsPerPage = 5
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const paginatedTicketSellers = ticketSellers?.sort((a, b) => a.name.localeCompare(b.name))?.slice(indexOfFirstPost, indexOfLastPost)
    const paginate = (number) => {
        setCurrentPage(number);
    }



    if (loading) {
        return (<Loader />);
    } else {

        return (
            <Container>

                <div style={{ width: '100%', textAlign: 'center' }}>
                    <p style={{ margin: 0, fontWeight: 'bold' }}>Box Office Seller Management</p>
                </div>

                {updateSellerInfo && <Modal open={true} title={'Box Office Seller Management'} onCancel={() => {

                    resetInputsState()
                    setUpdateSellerInfo(false)

                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >



                        <Form onSubmit={handleSellerSubmitUpdate}>

                            <Card>
                                <Card.Body >

                                    {/* <Card.Img variant="top" style={{ maxHeight: '350px' }} src={'https://st4.depositphotos.com/6903990/27898/i/450/depositphotos_278981062-stock-photo-beautiful-young-woman-clean-fresh.jpg'} /> */}

                                    <Row>

                                        <Col className='my-1' md={4}>
                                            <Card.Img variant="top" style={{ maxHeight: '350px' }} alt='face' src={imageLoaded ? `${sellerRowData.face}` : visualPlacer} onLoad={() => {

                                            }} onError={() => {
                                                setImageLoaded(false)
                                            }} />
                                        </Col>


                                        <Col className='my-1' md={8}>
                                            <Row>


                                                <Col className='my-1' md={12}>
                                                    <Form.Group controlId="name">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Name<span style={{ color: "red" }}> *</span></p>
                                                            </div>

                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="text"
                                                                placeholder="Enter name"
                                                                value={(sname != '' ? sname : sellerRowData?.name)}
                                                                onChange={(e) => {

                                                                    if (e.target.value !== '') {
                                                                        setSname(e.target.value)
                                                                    } else {
                                                                        setSname(null)
                                                                    }
                                                                }}

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>

                                                <Col className='my-1' md={12}>
                                                    <Form.Group controlId="email">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Email</p>
                                                            </div>

                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="email"
                                                                placeholder="Enter email"
                                                                value={email}
                                                                onChange={(e) => {
                                                                    setEmail(e.target.value)
                                                                }
                                                                }

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group controlId="mbNo.">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Mobile No.<span style={{ color: "red" }}> *</span></p>
                                                            </div>

                                                            <PhoneInput
                                                                country={'ug'}
                                                                value={phone}
                                                                localization={{ ug: 'Uganda' }}
                                                                onChange={(e) => {
                                                                    setPhone(e);
                                                                }}


                                                                disableDropdown
                                                                countryCodeEditable={false}
                                                                masks={{ ug: '... ... ...' }}

                                                                inputProps={{
                                                                    name: 'phone',
                                                                    required: true,

                                                                }}


                                                                inputStyle={{
                                                                    width: "100%",
                                                                    height: "30px",
                                                                    fontSize: "12px"
                                                                }}

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>

                                                    <Form.Group controlId="location">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Location</p>
                                                            </div>

                                                            <Form.Control
                                                                className=' input-box-events form-control-sm'
                                                                type="text"
                                                                placeholder="Enter Location"
                                                                value={location}
                                                                onChange={(e) => setLocation(e.target.value)}

                                                            />
                                                        </div>

                                                    </Form.Group>
                                                </Col>
                                                <Col className='my-1' md={12}>
                                                    <Form.Group className='my-2' controlId="faceImage">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                            <div className='mx-2 FormLabels'>
                                                                <p style={{ margin: 0 }}>Visual</p>
                                                            </div>
                                                            <Form.Control

                                                                accept='.jpeg,.jpg,.png'
                                                                name="faceImage"
                                                                onChange={(e) => {

                                                                    //setFaceImage(e.target.files[0]);
                                                                    setCropperImage(URL.createObjectURL(e.target.files[0]));
                                                                    setShowCropper(true)

                                                                }}
                                                                className=' input-box-events form-control-sm'

                                                                type="file" />
                                                        </div>

                                                    </Form.Group>

                                                </Col>

                                                <Col className='my-1' md={12} >

                                                    <Form.Group className='my-2' controlId="eventType">
                                                        <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>


                                                            <div className='d-flex flex-row   w-100'>



                                                                <Form.Check
                                                                    type="checkbox"
                                                                    label="Is Active"
                                                                    name="active"
                                                                    checked={isActive}
                                                                    onChange={(e) => {
                                                                        setIsActive(!isActive)
                                                                    }}

                                                                    className="my-0 mx-1 input-box-events form-control-sm"
                                                                />




                                                            </div>
                                                        </div>


                                                    </Form.Group>

                                                </Col>
                                            </Row>
                                        </Col>




                                    </Row>



                                </Card.Body>
                            </Card>



                            <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                                <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                    <GrIcon.GrUploadOption className='mx-1' style={{ width: '15px', height: '15px' }} /> | Update Seller
                                </Button>
                            </div>
                        </Form>



                    </div>


                </Modal>}

                {showCropper && cropperImage && <Modal open={true} title={`Seller Image Formating (600 * 600)`} onCancel={() => {
                    setShowCropper(false)
                }}
                    footer={[


                    ]}

                    className="allmodals"
                >

                    <div style={{ fontSize: '12px' }} >

                        <Card>
                            <Card.Body >
                                <Row>
                                    <ImageCropper image={cropperImage} setImage={setCropperImage} setOpen={setShowCropper} setCropResultFile={setFaceImage} windowWidth={'300px'} windowHeight={'300px'} aspectRatio={(600 / 600)} />
                                </Row>
                            </Card.Body>
                        </Card>

                    </div>


                </Modal>}

                <div style={{ fontSize: '12px' }} className='table-responsive'>

                    <Form onSubmit={handleSellerSubmit}>

                        <Card>
                            <Card.Body >
                                <Row>

                                    <Col className='my-1' md={6}>
                                        <Form.Group controlId="name">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Name<span style={{ color: "red" }}> *</span></p>
                                                </div>

                                                <Form.Control
                                                    className=' input-box-events form-control-sm'
                                                    type="text"
                                                    placeholder="Enter name"
                                                    value={sname}
                                                    onChange={(e) => setSname(e.target.value)}

                                                />
                                            </div>

                                        </Form.Group>
                                    </Col>




                                    <Col className='my-1' md={6}>
                                        <Form.Group controlId="email">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Email</p>
                                                </div>

                                                <Form.Control
                                                    className=' input-box-events form-control-sm'
                                                    type="email"
                                                    placeholder="Enter email"
                                                    value={email}
                                                    onChange={(e) => setEmail(e.target.value)}

                                                />
                                            </div>

                                        </Form.Group>
                                    </Col>



                                    <Col className='my-1' md={6}>
                                        <Form.Group controlId="mbNo.">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Mobile No.<span style={{ color: "red" }}> *</span></p>
                                                </div>

                                                <PhoneInput
                                                    country={'ug'}
                                                    value={phone}
                                                    localization={{ ug: 'Uganda' }}
                                                    onChange={(e) => {
                                                        setPhone(e);

                                                    }}


                                                    disableDropdown
                                                    countryCodeEditable={false}
                                                    masks={{ ug: '... ... ...' }}

                                                    inputProps={{
                                                        name: 'phone',
                                                        required: true,

                                                    }}


                                                    inputStyle={{
                                                        width: "100%",
                                                        height: "30px",
                                                        fontSize: "12px"
                                                    }}

                                                />
                                            </div>

                                        </Form.Group>
                                    </Col>

                                    <Col className='my-1' md={6}>
                                        <Form.Group controlId="Location">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>
                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Location<span style={{ color: "red" }}> *</span></p>
                                                </div>

                                                <Form.Control
                                                    className=' input-box-events form-control-sm'
                                                    type="text"
                                                    placeholder="Enter Location"
                                                    value={location}
                                                    onChange={(e) => setLocation(e.target.value)}

                                                />
                                            </div>

                                        </Form.Group>
                                    </Col>

                                    <Col className='my-1' md={6}>
                                        <Form.Group className='my-2' controlId="faceImage">
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: 'center' }}>

                                                <div className='mx-2 FormLabels'>
                                                    <p style={{ margin: 0 }}>Visual</p>
                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", alignItems: 'center', width: '100%' }}>
                                                    <div>
                                                        <div className='my-1 ' style={{ width: '100%', }} >


                                                            <ImagePreview selectedImage={faceImage} size={'600 * 600'} containerHeight='300px' containerWidth='300px' containerHeightMobile='200px' containerWidthMobile='200px' placeholderMessage={'Seller Image.'} />

                                                        </div>
                                                    </div>
                                                    <div style={{ width: '100%' }}>
                                                        <Form.Control

                                                            accept='.jpeg,.jpg,.png'
                                                            name="faceImage"
                                                            onChange={(e) => {


                                                                setCropperImage(URL.createObjectURL(e.target.files[0]));
                                                                setShowCropper(true)

                                                            }}
                                                            className=' input-box-events form-control-sm'

                                                            type="file" />
                                                    </div>
                                                </div>

                                            </div>

                                        </Form.Group>

                                    </Col>



                                </Row>



                            </Card.Body>
                        </Card>



                        <div className='mt-2' style={{ width: '100%', textAlign: 'center' }}>
                            <Button style={{ backgroundColor: "#FFFFFF", color: 'black' }} variant="primary" type="primary" htmlType='submit' >
                                <GiIcon.GiArchiveRegister className='mx-1' style={{ width: '15px', height: '15px' }} /> | Add Seller
                            </Button>
                        </div>
                    </Form>

                    {ticketSellers?.length > 0 ?
                        <Table striped hover style={{ cursor: "pointer" }}>
                            <thead>
                                <tr>
                                    <th>No.</th>
                                    <th>Name</th>
                                    <th> Status</th>
                                    <th>Mobile No.</th>
                                    <th> Email </th>
                                    <th> Location </th>
                                    <th>Operations </th>
                                </tr>
                            </thead>
                            <tbody>
                                {paginatedTicketSellers?.map((data, index) => (
                                    <tr key={index} onClick={() => { }}>
                                        <td >{((currentPage * 5) - postsPerPage) + (index + 1)}</td>
                                        <td >{data.name}</td>
                                        <td >{!(data.status) ? <ImCross style={{ height: "12px", width: "12px", color: 'red' }} /> : <TiIcons.TiTick style={{ height: "20px", width: "20px", color: 'green' }} />}</td>
                                        <td >{data.phone}</td>
                                        <td >{data.email}</td>
                                        <td >{data.location}</td>
                                        <td>


                                            <GrIcon.GrUploadOption className='mx-1' title='Update Seller Details' style={{ height: "15px", width: "15px" }} onClick={() => {
                                                setSname(data.name)
                                                setEmail(data.email)
                                                setPhone(data.phone)
                                                setLocation(data.location)
                                                setUpdateSellerInfo(true)
                                                setIsActive(data.status)
                                                setSellerRowData(data)

                                            }} />


                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </Table>

                        :

                        <p>No Sellers added.</p>

                    }
                    {ticketSellers?.length > 0 && (
                        <Pagination
                            postsPerPage={postsPerPage}
                            totalPosts={ticketSellers.length}
                            paginate={paginate}
                            currentPage={currentPage}
                        />
                    )}


                </div>

            </Container>
        );
    }
};

export default EventTicketSellerForm;
